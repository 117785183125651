import React from "react";
import styled from "styled-components";
import { MdChevronRight } from "react-icons/md";
import Button from "../components/common/Button";
import Column from "../components/common/Column";
import Container from "../components/common/Container";
import Row from "../components/common/Row";
import MeetIan from "../components/common/MeetIan";

import files from "../images/diligence.jpg";

const Investors = () => (
  <Page id="investors">
    <Hero>
      <Content>
        <h1>Welcome Investors</h1>
        <p>
          BetterMedics provides Healthcare Companionship to complex patients
          through synchronous Telehealth & Remote Patient Monitoring.
        </p>
      </Content>
    </Hero>
    <ExecutiveSummary>
      <Content>
        <h2>Get to know us</h2>
        <p>
          We are excited to start the conversation. You will find a few
          documents below that provide additional details about our venture and
          this funding round.
        </p>

        <Button
          href={
            process.env.PUBLIC_URL + "/files/BetterMedics_ExecutiveSummary.pdf"
          }
          target="_blank"
          rel="noopener noreferrer"
          styles={{ paddingLeft: "34px" }}
        >
          Executive Summary <MdChevronRight size={22} />
        </Button>
      </Content>
    </ExecutiveSummary>
    <OverviewVideo>
      <Row align="center">
        <Content>
          <VideoPlayer>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/Hev54CkYQK0?si=noc9L8hQ12_0JSbs"
              title="BetterMedics Overview"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </VideoPlayer>
        </Content>
      </Row>
    </OverviewVideo>
    <Diligence>
      <Container>
        <Content>
          <Row reverseMobile>
            <Column width="10%" />
            <Column align="left" width="40%">
              <h1>Investor Deck</h1>
              <p>
                For qualified investors, request access to our investor deck.
              </p>
              <Button
                href="https://docs.google.com/forms/d/e/1FAIpQLSe48l_o-cwf5aMBY8olGerSMvflwIWM4r6EzseqVTA--3Td2w/viewform?usp=sf_link"
                target="_blank"
                btnTheme="primaryText"
              >
                Request access
              </Button>
            </Column>
            <Column width="50%">
              <Image src={files} />
            </Column>
          </Row>
        </Content>
      </Container>
    </Diligence>
    <MeetIan />
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Content = styled.div`
  padding: 80px 0;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Section = styled.section``;

const Hero = styled(Section)`
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }

  ${Content} {
    max-width: 540px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 36px;
      max-width: 300px;
      margin: 0 auto 20px;
    }
    p {
      font-size: 17px;
    }
  }
`;

const ExecutiveSummary = styled(Section)`
  background-color: ${(props) => props.theme.backgroundColors.beige};

  h2 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 36px;
    font-size: 18px;
  }

  ${Content} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    max-width: 640px;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 30px;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;
      font-size: 17px;
    }
  }
`;

const OverviewVideo = styled(Section)`
  ${Content} {
    max-width: 720px;
    padding: 80px 0;
  }
`;

const VideoPlayer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const Diligence = styled(Section)`
  background-color: ${(props) => props.theme.backgroundColors.tertiary};

  h1 {
    margin-bottom: 24px;
  }
  p {
    max-width: 330px;
    margin-bottom: 36px;
  }

  img {
    max-width: 300px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      margin-bottom: 24px;
    }
    p {
      font-size: 17px;
      text-align: center;
      margin-bottom: 24px;
    }
    img {
      max-width: 160px;
      margin: 30px 0;
    }

    ${Content} {
      padding-bottom: 80px;
    }
  }
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

export default Investors;
