import React from "react";
import styled from "styled-components";
import Container from "./common/Container";
import Row from "./common/Row";

const Footer = () => (
  <Wrapper>
    <Container>
      <Legal>
        <Row align="right" mobileAlign="center">
          <p>{`© ${new Date().getFullYear()} BetterMedics, Inc.`}</p>
        </Row>
      </Legal>
    </Container>
  </Wrapper>
);

const Legal = styled.div`
  margin-top: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding: 30px 10px 40px;
`;

const Wrapper = styled.footer`
  padding: 0;

  span {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 17px;
    font-weight: 500;
    color: ${(props) => props.theme.textColors.primary};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.textColors.primary};

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  p {
    font-size: 13px;
    color: ${(props) => props.theme.textColors.tertiary};
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    text-align: left;

    span {
      display: none;
    }

    a {
      font-size: 15px;
      width: 100%;
    }

    ${Legal} {
      padding: 24px 0;
    }
  }
`;

export default Footer;
