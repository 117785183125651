import React from "react";
import styled from "styled-components";
import Button from "../components/common/Button";
import Callout from "../components/common/Callout";
import Column from "../components/common/Column";
import Container from "../components/common/Container";
import Row from "../components/common/Row";

import heroBG from "../images/story-hero.jpg";
import history from "../images/story-history.jpg";
import timely from "../images/timely-care.jpg";

const MISSION =
  "Our goal is simple - to improve the lives of our patients. We're a new layer of care in the home that provides companionship and clinical support 24 hours a day.";

const Press = () => (
  <Page id="press">
    <Hero>
      <HeroBackground>
        <img src={heroBG} alt="" />
      </HeroBackground>
      <HeroContent>
        <h2>Our Story</h2>
      </HeroContent>
    </Hero>

    <Callout text={MISSION} />

    <Copy>
      <Container>
        <Content>
          <Group>
            <Row valign="center" gap={80} mobileGap={40} reverseMobile>
              <Column align="left" width="50%">
                <p>
                  We began our jouney over 30 years ago by providing home health
                  care to chronically-ill, homebound individuals. We are
                  passionate about caring for high-needs patients- those who are
                  managing multiple chronic illnesses and those who have
                  functional limitations in their ability to care for themselves
                  (such as bathing or dressing) or perform routine daily tasks
                  (such as shopping or preparing food).
                </p>
              </Column>
              <Column width="50%">
                <Image src={history} alt="" />
              </Column>
            </Row>
          </Group>
          <Group>
            <Row valign="center" gap={80} mobileGap={40}>
              <Column width="50%">
                <Image src={timely} alt="" />
              </Column>
              <Column align="left" width="50%">
                <p>
                  In our time serving this population we have witnessed their
                  struggles with access to timely care, challenges with symptom
                  management, with loneliness and fear. In many cases these
                  patients go to the ER - which is traumatic for the patient and
                  their families. BetterMedics provides just-in-time
                  intervention. Through remote patient monitoring and a 24/7
                  CareLine, we focus on symptom management and the challenges
                  that accompany chronic illness. For our patients, being cared
                  for in the comfort of their home improves quality of life.
                </p>
              </Column>
            </Row>
          </Group>
        </Content>
      </Container>
    </Copy>
    <PressKit>
      <Container>
        <Content>
          <Row>
            <Column>
              <Button
                size="large"
                mobileSize="medium"
                href={
                  process.env.PUBLIC_URL + "/files/BetterMedics_PressKit.zip"
                }
                download
              >
                Download Press Kit
              </Button>
            </Column>
          </Row>
        </Content>
      </Container>
    </PressKit>
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Section = styled.section``;
const Content = styled.div``;

const Hero = styled(Section)`
  position: relative;
  height: 70vh;
  pointer-events: none;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 94px;
    color: white;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 48px;
    }
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  background-color: black;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0.7;
  }

  @media only screen and (max-width: 768px) {
    img {
      object-position: 80% 50%;
    }
  }
`;

const Copy = styled(Section)`
  ${Content} {
    padding: 100px 0;
  }
`;

const Group = styled.div`
  margin: 120px 0;

  p {
    font-size: 21px;
    line-height: 1.6;
    color: ${(props) => props.theme.textColors.primary};
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 768px) {
    margin: 80px 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      font-size: 17px;
      line-height: 1.4;
      text-align: center;
    }
  }
`;

const Image = styled.img`
  display: block;
  object-fit: cover;
  max-width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    min-height: 320px;
  }
  @media only screen and (max-width: 768px) {
    min-height: auto;
  }
`;

const PressKit = styled(Section)`
  ${Content} {
    margin: 20px 0;

    background-color: ${(props) => props.theme.backgroundColors.tertiary};
    border-radius: 20px;
    padding: 100px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 !important;

    > div {
      width: 100%;
    }

    ${Content} {
      margin: 0 0 20px;
      border-radius: 0;
      padding: 100px 20px;
    }
  }
`;

export default Press;
