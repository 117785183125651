import React from "react";
import styled from "styled-components";
import Container from "./Container";
import Column from "./Column";
import Row from "./Row";
import Button from "./Button";

import ian from "../../images/ian.webp";

const MeetIan = () => (
  <Section>
    <Container>
      <Content>
        <Row reverseMobile mobileGap={30}>
          <Column align="left">
            <h3>TechCrunch Disrupt Meetup</h3>
            <p>
              We would be thrilled to schedule time with you during Disrupt.
              Please use the button below to email our CEO directly so we can
              lock in time together.
            </p>
            <Button
              href="https://calendly.com/ian-stanich"
              target="_blank"
              btnTheme="techcrunch"
            >
              Let's Meet
            </Button>
          </Column>
          <Column align="right">
            <span>Ian Stanich, CEO</span>
            <Circle>
              <img src={ian} alt="Ian Stanich, CEO" />
            </Circle>
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section``;

const Content = styled.div`
  margin: 60px auto 30px;
  background: #00d301;
  background: linear-gradient(306deg, #00d301, #36c275 50%, #00a562);
  border-radius: 20px;
  padding: 40px 50px;
  max-width: 960px;

  h3 {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    font-size: 48px;
    color: white;
    text-align: left;
    margin-bottom: 18px;
  }
  p {
    color: white;
    text-align: left;
    margin-bottom: 30px;
  }

  span {
    position: absolute;
    bottom: 20px;
    right: 0;
    z-index: 2;
    padding: 4px 8px;
    border-radius: 2px;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background-color: white;
    box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
    box-sizing: border-box;

    h3 {
      font-size: 36px;
      text-align: center;
    }
    p {
      text-align: center;
    }

    span {
      right: 20px;
    }
  }
`;

const Circle = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  overflow: hidden;
  margin-right: 20px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0;

    width: 240px;
    height: 240px;
  }
`;

export default MeetIan;
