import React, { useState } from "react";
import styled from "styled-components";
import ReactHeadroom from "react-headroom";
import Button from "./common/Button";

import logo from "../images/bettermedics-logo.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ReactHeadroom>
      <Wrapper id="header-navigation">
        <Nav>
          <Content>
            <a href="/">
              <Logo src={logo} />
            </a>
            <MobileBtn open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
              <svg
                aria-hidden="true"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
              >
                <g>
                  <path d="M5 13h90v14H5z" />
                  <path d="M5 43h90v14H5z" />
                  <path d="M5 73h90v14H5z" />
                </g>
              </svg>
            </MobileBtn>
            <AuxMenu open={menuOpen}>
              <LinksWrapper>
                <Link
                  href="/investors"
                  active={
                    window.location.pathname === "/investors"
                      ? "true"
                      : undefined
                  }
                >
                  Investors
                </Link>
                <Link
                  href="/press"
                  active={
                    window.location.pathname === "/press" ? "true" : undefined
                  }
                >
                  Press
                </Link>
                <Link
                  href="/partners"
                  active={
                    window.location.pathname === "/partners"
                      ? "true"
                      : undefined
                  }
                >
                  Partners
                </Link>
              </LinksWrapper>
              <BtnWrapper>
                <Button
                  size="small"
                  mobileSize="medium"
                  mobileExpand
                  href="mailto:contact@bettermedics.org"
                  target="_blank"
                >
                  Contact Us
                </Button>
              </BtnWrapper>
            </AuxMenu>
          </Content>
        </Nav>
      </Wrapper>
    </ReactHeadroom>
  );
};

const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  box-sizing: border-box;

  padding: 10px 0;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  height: 68px;
  padding: 0 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    padding: 0 12px 0 20px;
  }
`;

const MobileBtn = styled.button`
  display: none;
  border: none;
  border-radius: 0;
  background: transparent;
  color: ${(props) => props.theme.textColors.primary};
  cursor: pointer;
  padding: 1em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;

  font-size: 0.875em;
  padding: 1em;
  margin-left: auto;

  svg {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    position: relative; /* Align more nicely with capital letters */
    top: -0.0625em;

    transform: scale(1.75);
  }

  g {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1em 1em;
  }

  path {
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;

    &:first-child {
      transform-origin: 1em 2.5em;
    }
    &:last-child {
      transform-origin: 1em 4.5em;
    }
  }

  ${({ open }) =>
    open &&
    `
    path {
      opacity: 0;
      &:first-child {
        transform: rotate(45deg) translateY(0) translateX(0);
        opacity: 1;
      }
      &:last-child {
        transform: rotate(-45deg) translateY(0em) translateX(0em);
        opacity: 1;
      }
    }
  `}

  @media only screen and (max-width: 992px) {
    display: inline-block;
  }
`;

const Logo = styled.img`
  display: block;
  height: 36px;
  transform: translateZ(0);
  object-fit: contain;

  @media only screen and (max-width: 550px) {
    width: 170px;
  }
`;

const Link = styled.a`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  padding: 6px 10px;
  letter-spacing: 0.3px;
  opacity: 0.9;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ active, theme }) =>
    active &&
    `
    font-weight: 600;
    &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 5px;
      border-radius: 10px;
      background-color: ${theme.colors.tertiary};
    }
  `}

  @media only screen and (max-width: 768px) {
    ${({ active }) =>
      active &&
      `
        &::after {
          display: none;
        }
      `}
  }
`;

const AuxMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;

  @media only screen and (max-width: 992px) {
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 68px;
    height: 100vh;
    z-index: 100;
    display: ${(props) => (props.open ? "block" : "none")};

    background-color: ${(props) => props.theme.backgroundColors.lightAlt};
    border-top: 2px solid ${(props) => props.theme.colors.border};

    box-shadow: 0 10px 10px rgba(28, 38, 83, 0.08);
    border-radius: 0 0 10px 10px;
    padding: 5px 0;

    ${Link} {
      display: block;
      text-align: center;
      border-top: 1px dashed ${(props) => props.theme.colors.border};
      margin: 0;
      padding: 20px;

      &:first-child {
        border-top: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${Link} {
      &:last-child {
        border-bottom: 1px dashed ${(props) => props.theme.colors.border};
      }
    }

    > a {
      margin: auto auto 30px;
    }
  }
`;

const LinksWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 50px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    gap: 0;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    padding: 30px;
  }
`;

export default Header;
