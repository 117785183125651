import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { MdChevronRight, MdOutlineArrowForward } from "react-icons/md";
import Container from "../components/common/Container";
import Row from "../components/common/Row";

const CONFIG = [
  {
    id: "investors",
    title: "Investors",
    subtitle: "Grow with us",
    color: "#E0F0E4",
  },
  {
    id: "press",
    title: "Press",
    subtitle: "Discover our story",
    color: "#DDE5F0",
  },
  {
    id: "partners",
    title: "Partners",
    subtitle: "Make a difference",
    color: "#F6E8D9",
  },
];

const Home = () => (
  <Page id="home">
    <Section>
      <Container>
        <Content>
          <h1>Thanks for visiting our booth</h1>
          <p>Let us provide more information</p>

          <Row gap={30} mobileGap={16}>
            {CONFIG.map((item) => (
              <BlockBtn key={item.id} $bg={item.color} href={`/${item.id}`}>
                <div>
                  <h5>{item.subtitle}</h5>
                  <h2>{item.title}</h2>
                </div>
                <MoreInfo>
                  More info <MdChevronRight />
                </MoreInfo>
              </BlockBtn>
            ))}
          </Row>
        </Content>
      </Container>
    </Section>
    <Section>
      <Container>
        <Website>
          <Link>
            <a
              href="https://bettermedics.org"
              target="_blank"
              alt=""
              rel="noreferrer"
            >
              Visit our Website
            </a>
            <MdOutlineArrowForward />
          </Link>
        </Website>
      </Container>
    </Section>
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Content = styled.div`
  padding: 80px 0;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Section = styled.section`
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 36px;
      max-width: 300px;
      margin: 0 auto 20px;
    }
    p {
      font-size: 18px;
    }
  }
`;

const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  font-size: 15px;
  font-weight: 600;
  color: ${(props) => props.theme.textColors.secondary};

  margin-top: 40px;

  svg {
    font-size: 22px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const BlockBtn = styled.a`
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.$bg ?? "white"};
  border-radius: 20px;
  flex: 1;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;

  div:first-child {
    flex: 1;
  }
  h5 {
    font-size: 17px;
    font-family: ${(props) => props.theme.fonts.secondary};
    margin: 0;
  }
  h2 {
    margin: 6px 0 14px;
  }

  &:hover {
    ${MoreInfo} {
      color: ${(props) => darken(0.08, props.theme.colors.primary)};
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
    flex: initial;
    width: 100%;
    padding: 24px 18px 24px 24px;

    h2 {
      margin: 5px 0 0;
      font-size: 28px;
    }
    h5 {
      font-size: 16px;
      opacity: 0.85;
    }
  }
`;

const Website = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 80px 20px;

  @media only screen and (max-width: 768px) {
    padding: 50px 0 50px 20px;
  }
`;

const Link = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  a,
  svg {
    color: ${(props) => props.theme.colors.primary};
  }

  a {
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
  }

  svg {
    font-size: 20px;
  }

  &:hover {
    a,
    svg {
      color: ${(props) => props.theme.colors.tertiary};
    }
  }

  @media only screen and (max-width: 768px) {
    a {
      font-size: 17px;
    }
    svg {
      font-size: 18px;
    }
  }
`;

export default Home;
