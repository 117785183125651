import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./index.css";

import MainLayout from "./layouts/MainLayout";

import HomePage from "./pages/Home";
import ErrorPage from "./pages/404";

import Investors from "./pages/Investors";
import Partners from "./pages/Partners";
import Press from "./pages/Press";

import Theme from "./Theme";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Theme>
      <Router>
        <Switch>
          <Route exact path="/investors">
            <MainLayout>
              <Investors />
            </MainLayout>
          </Route>
          <Route exact path="/press">
            <MainLayout>
              <Press />
            </MainLayout>
          </Route>
          <Route exact path="/partners">
            <MainLayout>
              <Partners />
            </MainLayout>
          </Route>
          <Route exact path="/404">
            <ErrorPage />
          </Route>
          <Route exact path="/">
            <MainLayout>
              <HomePage />
            </MainLayout>
          </Route>
          <Route
            render={({ location }) => {
              return location.pathname.indexOf("/files/") > -1 ? null : (
                <Redirect
                  to={{ pathname: "/404", state: { from: location } }}
                />
              );
            }}
          />
        </Switch>
      </Router>
    </Theme>
  </React.StrictMode>
);
